import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import DarkFooter from "../components/Footer.js";
import LandingPageHeader from "../components/PageHeader.js";

function Fun() {
  React.useEffect(() => {
    document.title = "The Dragonfly in Ocean Isle Beach - Island Fun";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <LandingPageHeader 
            title="Some of our favorite things to do around the island."
            intro="HAVE SOME FUN"
            src={require('../assets/img/welcome.jpg')}
          />
        <Container>
          <h2 className="title">Some things to do around the island</h2>
          <p>Click the <img src={require('../assets/img/google-map-icon.png')} alt="example icon" className="img-align-text"/> icon to see the places or just click on a place in the map for more details.</p>
          <div className="map-responsive">
            <iframe title="Google map of things to do" src="https://www.google.com/maps/d/u/3/embed?mid=1K3pcGExq_26-L3fOLn0Ai_vzNtBtVxzs" width="100%" height="400"></iframe>
          </div>
        </Container>
        <DarkFooter />
    </>
  );
}

export default Fun;
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import ReactPlayer from "react-player"

// reactstrap components
import {
  Container, Row, Col,
} from "reactstrap";


// core components
import SiteNavBar from "../components/SiteNavbar.js";
import DarkFooter from "../components/Footer.js";
import LandingPageHeader from "../components/PageHeader.js";

function OIB() {
  React.useEffect(() => {
    document.title = "The Dragonfly in Ocean Isle Beach - Island Fun";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        <LandingPageHeader
         title="Learn more about the island and what it has to offer."
         intro="Ocean Isle Beach (OIB)" 
         src={require('../assets/img/sandals.jpeg')}
         />
        <Container>
          <Row>
            <Col md="6">
            <h2 className="title">Something for Everyone</h2>
              <p>
                Situated on the coast between Wilmington, NC and Myrtle Beach, SC the island of Ocean Isle Beach is approximately
                4.5 square miles in size, with almost 25% of that being water.
              </p>
              <p>
                Connected to the mainland by the Odell Williamson bridge, this small town has something for the entire family like it's <a href="https://oibpier.com/" target="_blank">fishing pier</a>, <a href="https://museumplanetarium.org/" target="_blank">Museum of Coastal Carolina</a>,
                it's direct access to the <a href="https://en.wikipedia.org/wiki/Intracoastal_Waterway" target="_blank">Intercoastal Waterway</a> or it's beach paths 
                every 500 feet.
              </p>

              <h4>Families</h4>
              <p>
              With outdoor concerts and festivals, mini-golf, go-karts and the family favorite <a href="https://www.shallotteriverswamppark.com/" target="_blank">Shallotte River Swamp Park</a> there is fun for all ages.  Check out
              our <a href="islandfun">Island Fun</a> section for to find some of our favorites.
              </p>
            </Col>
            <Col md="6" className="text-center">

                <br /><br />
                <div>
                <ReactPlayer
                        url="https://www.youtube.com/watch?v=t8-qu7WtCdY"
                        width='100%'
                   />
                </div>

                <br />
                <strong>Live Cams:</strong>
                <span className="nobr link"><a href="https://youtu.be/0cVnY6M-lKw" target="_blank">Jinks Creek</a></span>
                |
                <span className="nobr link"><a href="https://www.oifc.com/webcam" target="_blank">OIFC</a></span>
                |
                <span className="nobr link"><a href="https://www.oceanisleinn.com/webcam" target="_blank">Ocean Isle Inn</a></span>

            </Col>
          </Row>
          <Row>
            <Col md="12">

              <h4>Fishing</h4>
                <p>
                  For those who love to fish, there are so many options and so many types of different fish to catch.
                  Bring your own boat and park at your private dock at the Dragonfly, use
                  the <a href="https://www.ncbrunswick.com/listing/ocean-isle-beach-boat-ramp/112/" target="_blank">public boat ramp</a>, fish off
                  the <a href="https://oibpier.com/" target="_blank">pier</a> or take one of the many charters available.
                </p>

              <h4>Golfing</h4>
              <p>
                Off island, nearby, you'll find over 100 golf courses with spectacular views and links for everyone from the amatuers to the pros.
              </p>

              <h4>Dining</h4>
              <p>
                With so many different options you can always find a restaraunt that suits your dining needs.  From our <a href="https://theoysterrock.com/" target="_blank">fantastic seafood</a>,
                to the <a href="http://sugarshackoib.com/" target="_blank">tasty Jamacain</a> and <a href="https://www.robertosoib.com/" target="_blank">delicious Italian</a> to the <a
                href="https://inletview.com/" target="_blank">breath taking views</a> they offer.
              </p>

              <h4>Learn More</h4>
                <ul>
                  <li><a href="https://www.southernliving.com/souths-best/ocean-isle-beach" target="_blank">Southern Living: Best Tiny Town 2018</a></li>
                  <li><a href="https://www.tripadvisor.com/Tourism-g49406-Ocean_Isle_Beach_North_Carolina-Vacations.html" target="_blank">Ocean Isle on TripAdvisor</a></li>
                  <li><a href="https://www.oibgov.com/" target="_blank">Town Government</a></li>
                  <li><a href="https://www.oceanislebeach.org/" target="_blank">Unofficial Visitors Guide</a></li>
                  <li><a href="https://www.ncbrunswick.com/islands-towns/ocean-isle-beach/" target="_blank">Brunsiwck Islands Website</a></li>
                </ul>

               <h4>Popular On Facebook</h4>
               <ul>
                <li><a href="https://www.facebook.com/groups/2204868922/" target="_blank">Ocean Isle Beach Bums</a></li>
                <li><a href="https://www.facebook.com/OCEANISLEBEACHPIER/" target="_blank">Ocean Isle Beach Pier</a></li>
                <li><a href="https://www.facebook.com/groups/703346989732721/" target="_blank">I Love Ocean Isle Beach</a></li>
                <li><a href="https://www.facebook.com/townofoib/">Town of Ocean Isle Beach</a></li>
               </ul>

            </Col>
          </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default OIB;
/*eslint-disable*/
import React from "react";

// reactstrap components
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

function Footer() {
  return (
    <div>
      <footer className="footer" data-background-color="black">
        <Container>
          <Row>
            <Col md="8">
              <nav>
                <ul>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="index"
                    >
                      Home
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="virtual"
                    >
                      Tour
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="amenities"
                    >
                    Amenities
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="gallery"
                    >
                      Gallery
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="islandfun"
                    >
                      Island Fun
                    </a>
                  </li>
                  <li>
                    <a
                      className="btn-link btn-default"
                      href="oceanislebeach"
                    >
                      About OIB
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className="btn btn-primary"
                      href="booknow"
                    >
                      Book Now!
                    </a>
                  </li>
                </ul>
              </nav>
            </Col>
            <Col md="4">
            <div className="copyright" id="copyright">
              © {new Date().getFullYear()}, DragonflyOIB LLC
            </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;

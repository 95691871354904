import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import LandingPageHeader from "../components/PageHeader.js";
import DarkFooter from "../components/Footer.js";
import Carousel from "../components/Carousel.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        <LandingPageHeader
         title="Private dock, quick walk to beach, and it sleeps twelve people?"
         intro="WELCOME TO YOUR NEXT VACATION" 
         />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Welcome to the Dragonfly at Ocean Isle Beach</h2>
              <h5 className="description text-left">
                Situated on a canal on the island of <a href="oceanislebeach">Ocean Isle Beach</a> in North Carolina, this four bedroom, family friendly
                canal home is less than five hundred steps from the beach.  Featuring wonderful outdoor living spaces, a private dock,
                a fenced-in yard and a long list of <a href="amenities">amenities</a> - the Dragonfly has a something for everyone!
              </h5>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
          <div className="section-story-overview">
            <Row>
              <Col md="6">
                <div
                  className="image-container image-left d-none d-md-block"
                  style={{
                    backgroundImage:
                      "url(" + require("../assets/img/deck-sunrise.jpg") + ")"
                  }}
                >
                    <p className="blockquote blockquote-info">
                      Whether you own a boat, rent jet skis or use the Dragonfly kayaks, an adventure on the intercoastal and atlantic is always just steps 
                      away.
                    </p>
                </div>
              </Col>
              <Col md="5">
                <div
                  className="image-container image-right d-none d-md-block"
                  style={{
                    backgroundImage:
                      "url(" + require("../assets/img/canal-view.JPG") + ")"
                  }}
                >
                </div>

                <Carousel />

                <h2>
                  A vacation for everyone.
                </h2>
                <p>
                  <em>Like fishing or boating?  Hanging at the beach? Just want to chill and hang out?</em>
                </p>

              </Col>
            </Row>
            <Row>
              <Col md="6">

              <div
                  className="image-container image-right d-none d-md-block"
                  style={{
                    backgroundImage:
                      "url(" + require("../assets/img/rear-view-night.jpg") + ")"
                  }}
                >
                </div>

              </Col>
              <Col md="5">
                <p>
                  With an outdoor area that features a speaker system, TV, fridge, ice machine and tons of seating, there is no reason to leave.
                  You can even jump off the dock to cool down if one of the many outdoor fans aren't keeping you cool.
                </p>
                <p>
                  Or maybe you're the type that likes the beach.  At a quick five minute walk away, you can use the provided beach cart and chairs or 
                  take your own.  You can even take the Dragonfly's bikes there.
                </p>
                <p>
                  Better yet, you love taking your (or one you rented) boat, jet ski's, or other PWC down the intercoastal or out into the 
                  beautiful Atlantic Ocean.  It's easy at the Dragonfly, with your own private dock right off the back yard.
                </p>
                <p>
                  Whatever it is, your family and friends will have a great time.
                </p>

                <p>Take the <a href="virtual">virtual tour</a> and see what awaits you.</p>
              </Col>
            </Row>
          </div>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
import React from 'react';

class Facebook extends React.Component {
 componentDidMount(){
    window.location.href = 'https://www.facebook.com/dragonflyoib/'; 
  }

  render() {
    return (
      <div>
       <h2>Visit Facebook!</h2>
      </div>
    )
  }
}

export default Facebook;
/*

=========================================================
* Now UI Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ReactGA from 'react-ga';

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/nucleo-icons-page-styles.css";
// pages for this kit
import Index from "./views/Index.js";
import Amenities from "./views/Amenities.js";
import Gallery from "./views/Gallery.js"
import Virtual from "./views/Virtual.js"
import IslandFun from "./views/IslandFun.js"
import OceanIsle from "./views/OceanIsleBeach.js"
import Email from "./views/Email";
import BookNow from "./components/BookNow";
import Facebook from "./components/Facebook";
import Twitter from "./components/Twitter";

ReactGA.initialize('UA-154561410-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <Routes>
        <Route 
          path="/index" 
          element={<Index />}
        />
        <Route 
          path="/"
          element={<Navigate to='/index' replace />} 
        />
        <Route
          path="/amenities"
          element={<Amenities />}
        />
        <Route
          path="/gallery"
          element={<Gallery />}
        />
        <Route
          path="/virtual"
          element={<Virtual />}
        />
        <Route
          path="/islandfun"
          element={<IslandFun />}
        />
        <Route
          path="/oceanislebeach"
          element={<OceanIsle />}
        />
        <Route
          path="/email"
          element={<Email />}
        />
        <Route
          path="/booknow"
          element={<BookNow />}
        />
        <Route
          path="/facebook"
          element={<Facebook />}
        />
        <Route
          path="/twitter"
          element={<Twitter />}
        />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

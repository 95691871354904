import React from "react";

// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

import logo from '../assets/img/logo-white.png'

function SiteNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      <Navbar className="fixed-top" expand="lg" color="info">
          <div className="navbar-translate">
            <NavbarBrand
              href="index"
              id="navbar-brand"
            >
              <img src={logo} height="50" alt="DragonflyOIB - Ocean Isle Beach, North Carolina" />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem>
              <NavLink
                href="index"
                className="d-block d-lg-none"
              >
                <i className="now-ui-icons business_bank"></i>
                <p>Home</p>
              </NavLink>
            </NavItem>    
            <NavItem>
                <NavLink
                  href="virtual"
                >
                  <i className="now-ui-icons education_glasses"></i>
                  <p>Tour</p>
                </NavLink>
              </NavItem> 
              <NavItem>
                <NavLink
                  href="amenities"
                >
                  <i className="now-ui-icons travel_info"></i>
                  <p>Amenities</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="gallery"
                >
                  <i className="now-ui-icons media-1_camera-compact"></i>
                  <p>Gallery</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="islandfun"
                >
                  <i className="now-ui-icons location_map-big"></i>
                  <p>Island Fun</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="oceanislebeach"
                >
                  <i className="now-ui-icons travel_info"></i>
                  <p>About OIB</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <Button
                  className="nav-link btn-primary text-left"
                  href="booknow"
                  target="_blank"
                >
                  <i className="now-ui-icons ui-1_calendar-60"></i>&nbsp;&nbsp;
                  <p>Reserve Now!</p>
                </Button>
              </NavItem>

              <NavItem className="no-hover d-none d-lg-inline">
                <NavLink
                  className="btn-facebook"
                  href="facebook"
                  target="_blank"
                >
                    <i className="fab fa-facebook lg"></i>
                </NavLink>
              </NavItem>

              <NavItem
                className="d-inline d-lg-none"
              >
                <Button
                  className="nav-link text-left"
                  color="info"
                  target="_blank"
                  href="facebook"
                >
                  <i className="fab fa-facebook-square"></i>&nbsp;&nbsp;
                  <p>Follow Us on Facebook!</p>
                </Button>
              </NavItem>

            </Nav>
          </Collapse>
      </Navbar>
    </>
  );
}

export default SiteNavbar;

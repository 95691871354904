import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import LandingPageHeader from "../components/PageHeader.js";
import DarkFooter from "../components/Footer.js";

function Amenities() {
  React.useEffect(() => {
    document.title = "Amenities at the Dragonfly in Ocean Isle Beach";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        <LandingPageHeader 
          title="What sets the Dragonfly apart from other properties?"
          intro="WE MAKE IT HOME"
          src={require('../assets/img/the-kayaks.jpg')}
        />
        <Container>
          <Row>
            <Col>
              
              <h2 className="title">Our Amenities</h2>

              <p>Inside, you and your guests will be treated to an updated home with an open living space that features a well outfitted kitchen that has a 
              snack bar and a dining area then opens to a family room with comfortable seating and a 58 inch TV. Below the TV you’ll find tons of games 
              to play with the family and a take/leave reading library.</p>
              
              <p>Our high tech home features fiber optic wi-fi, smart locks on all doors and charging stations next to all seating areas along with thousands of
                streaming movies/tv shows, an Amazon echos to play music (inside and out) that features Spotify and Pandora as well as acts as your personal
                concierge.</p>

              <p>Outdoors, you can enjoy the intimate screened in porch with seating for two and ceiling fan, or spread out across the over-sized deck and enjoy 
                one of the rocking chairs, wicker chairs or even enjoy afternoon snacks at the covered patio table. When you’re ready for some outdoor activities, 
                head to the private dock and launch one of the kayaks or grab a pole and try your luck fishing for dinner!</p>

              <p>Guests also have access to our bikes which provide the perfect transportation around the island!</p>

              <h4>Mostly Complete List (We're always adding!)</h4>
              <br />

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Where/What</th>
                    <th>Amenities</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Overall house</td>
                    <td>
                      <ul>
                        <li>Amazon Echo's located throughout with Premium Spotify</li>
                        <li>Blazingly fast fiber optic WiFi</li>
                        <li>Smart locks</li>
                        <li>Device charging areas throughout</li>
                        <li>Full size washer/dryer</li>
                        <li>Fenced in backyard</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Bedrooms</td>
                    <td>
                      <ul>
                        <li>1 Queen with Sitting area</li>
                        <li>1 Queen, 1 Twin with Sitting area</li>
                        <li>1 Queen with Sitting area</li>
                        <li>2 Bunks (Twin over Full & Twin over Twin) with 2 sitting areas</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Bathrooms</td>
                    <td>
                      <ul>
                        <li>2 Full Indoor</li>
                        <li>1 Outdoor (shower, toilet, sink)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Fully Equipped Kitchen</td>
                    <td>
                      <ul>
                        <li>Fridge with filtered water/ice</li>
                        <li>Stove/Oven</li>
                        <li>Dishwasher</li>
                        <li>Microwave</li>
                        <li>Garbage Disposal</li>
                        <li>Toaster Oven</li>
                        <li>Keurig</li>
                        <li>Blender</li>
                        <li>Crockpot</li>
                        <li>Service for 12</li>
                        <li>And much more…</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Indoor Entertainment</td>
                    <td>
                      <ul>
                        <li>Smart (Fire)) TV’s in all rooms</li>
                        <li>DVD Player & fun collection of movies</li>
                        <li>Lots of board games</li>
                        <li>Xbox 360 in kids room (with Bunks)</li>
                      </ul>
                    </td>
                  </tr>    
                  <tr>
                    <td>Outdoor Amenities</td>
                    <td>
                      <ul>
                        <li>Grill</li>
                        <li>Full size fridge with freezer</li>
                        <li>Stand alone ice maker</li>
                        <li>Smart TV</li>
                        <li>Speaker system powered by Alexa</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Outdoor Seating</td>
                    <td>
                      <ul>
                        <li>2 outdoor dining areas</li>
                        <li>3 separate patios</li>
                        <li>Screened-in porch</li>
                        <li>Pub style gaming area</li>
                        <li>Hammock & Bench Swing</li>
                        <li>Rocking chairs and other seating scattered throughout</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Outdoor Activities</td>
                    <td>
                      <ul>
                        <li>Private Dock (swimming, fishing, and boating)</li>
                        <li>Kayaks (and life jackets)</li>
                        <li>Bikes</li>
                        <li>Basketball</li>
                        <li>Darts</li>
                        <li>Cornhole & other games</li>
                        <br />
                        <em>Savings from not having to rent the above is easily over $300</em>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Beach Amenities</td>
                    <td>
                      <ul>
                        <li>Beach cart</li>
                        <li>Chairs</li>
                        <li>Umbrella</li>
                        <li>Boogie boards</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </Table>

            </Col>
          </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}


export default Amenities;
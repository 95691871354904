import React from "react";

// reactstrap components
import {
  Container, Row, Col
} from "reactstrap";

import ResponsiveEmbed from "react-responsive-embed";

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import DarkFooter from "../components/Footer.js";
import LandingPageHeader from "../components/PageHeader.js";

function Virtual() {
  React.useEffect(() => {
    document.title = "The Dragonfly in Ocean Isle Beach - Virtual Tour";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
      <LandingPageHeader
         title="Take a walk through the house and make yourself comfortable."
         intro="VIRTUAL TOUR"
         src={require('../assets/img/kitchen-2.jpeg')}
         />
        <Container>
          <Row>
            <Col>
              <br />
              <ResponsiveEmbed src='https://my.matterport.com/show/?m=qzY2Lv5GiEv' allowFullScreen />
            </Col>
          </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Virtual;
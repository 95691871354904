import React from 'react';

class Twitter extends React.Component {
 componentDidMount(){
    window.location.href = 'https://twitter.com/dragonflyoib'; 
  }

  render() {
    return (
      <div>
       <h2>Visit Twitter!</h2>
      </div>
    )
  }
}

export default Twitter;
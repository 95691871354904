import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function PageHeader(props) {

  const pageHeader = React.createRef();

  React.useEffect(() => {

    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current) {
            pageHeader.current.style.transform =
              "translate3d(0," + windowScrollTop + "px,0)";
        }
      };

      window.addEventListener("scroll", updateScroll);

      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };

    }

  });

  let bckSrc = require("../assets/img/porch-view.jpg");

  if (props.src !== undefined) {
    bckSrc = props.src;
  }
  
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + bckSrc + ")"
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            <h1 className="title">{props.title}</h1>
            <h2>{props.intro}</h2>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PageHeader;

import React from 'react';

class BookNow extends React.Component {
 componentDidMount(){
    window.location.href = 'https://www.sloanevacations.com/vacation-rentals/20rich-dragonfly-canal-house'; 
  }

  render() {
    return (
      <div>
       <h2>Book Now!</h2>
      </div>
    )
  }
}

export default BookNow;
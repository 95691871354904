import React from "react";

// reactstrap components
import {
  Container, Row, Col, Input, InputGroup, Button, InputGroupText, Alert
} from "reactstrap";

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import DarkFooter from "../components/Footer.js";
import LandingPageHeader from "../components/PageHeader.js";


function Email() {

  const [status, setStatus] = React.useState("");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  const submitForm = function(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    if (data.get("g-recaptcha-response") !== "") {
      data.delete("g-recaptcha-response");
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          setStatus("SUCCESS");
        } else {
          setStatus("ERROR");
        }
      };
      xhr.send(data);  
    }
    else {
      setStatus("ERROR");
    }
    return false;
  }

  React.useEffect(() => {
    document.title = "The Dragonfly in Ocean Isle Beach - Virtual Tour";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
      <LandingPageHeader
         title="Have a question about the house?  About Ocean Isle?"
         intro="CONTACT US" 
         src={require('../assets/img/inside-shot.png')}
         />

      {
        status === "ERROR" && 
        <Alert color="danger">
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons objects_support-17"></i>
            </div>
            <strong>OOOPS!</strong> Make sure you check the "I'm not a robot" checkbox.
          </Container>
        </Alert>
      }
      {
        status === "SUCCESS" && 
        <Alert color="success">
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons objects_support-17"></i>
            </div>
            <strong>THANKS!</strong> We will get back to you ASAP.
          </Container>
        </Alert>
      }


        <Container>
        <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">

                <h2 className="title">How Can We Help?</h2>

                  <form
                    onSubmit={submitForm}
                    action="https://formspree.io/xjvgeyve"
                    method="POST"
                  >

                    <InputGroup
                      className={
                        "input-lg" + (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                      <Input
                        placeholder="Name..."
                        name="name"
                        type="text"
                        required
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      ></Input>
                    </InputGroup>

                    <InputGroup
                      className={
                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                      <Input
                        placeholder="Email..."
                        required
                        type="email"
                        name="_replyto"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                    </InputGroup>
                    <div className="textarea-container">
                      <Input
                        required
                        cols="80"
                        name="message"
                        placeholder="Type a message..."
                        rows="4"
                        type="textarea"
                      ></Input>
                    </div>
                    <br />
                    <div className="g-recaptcha" data-sitekey="6LdZZMoUAAAAAMXIVBdPazXcqsap24WuvEjMlLJO"></div>
                    <br />
                    <div className="send-button">
                      <Button disabled={status === "SUCCESS"} block className="btn-round btn-primary"size="lg">Send Message</Button>
                    </div>
                  </form>

              </Col>
            </Row>
        </Container>
        <DarkFooter />
      </div>
    </>
  );
  
}

export default Email;
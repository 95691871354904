import React, { useState } from "react";

// gallery stuff
import Carousel, { Modal, ModalGateway } from "react-images";
import StackGrid, { transitions, easings } from  "react-stack-grid";
import axios from "axios"

// core components
import SiteNavBar from "../components/SiteNavbar.js";
import DarkFooter from "../components/Footer.js";
import LandingPageHeader from "../components/PageHeader.js";
import { Container, Row, Col } from "reactstrap";

function CustomGallery() {
  React.useEffect(() => {

    document.title = "The Dragonfly in Ocean Isle Beach - The Gallery";
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");

    loadPhotos();

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };

  });

  const transition = transitions.scaleDown;

  // eslint-disable-next-line no-unused-vars
  const [photos, setPhotos] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [currentImage, setCurrentImage] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = e => {
    setCurrentImage(parseInt(e.target.dataset.index));
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const loadPhotos = () => {

    if (photos && photos.length > 0) return;

      axios("https://pv95xi9aw9.execute-api.us-east-1.amazonaws.com/default/dragonly-gallery")
        .then(response => {
          //console.log(response.data)
          response.data.images.forEach((item, i) => item.key=i);
          setPhotos(response.data.images);
        })
        .catch(error => {
          console.log("error fetching data", error);
        })
  }

  return (
    <>
      <SiteNavBar />
      <div className="wrapper">
        <LandingPageHeader 
            title="See all the things that the the Dragonfly has to offer."
            intro="THE GALLERY"
            src={require('../assets/img/just-dock-view.jpg')}
          />
          <Container>
            <Row>
              <Col>
              <h2 className="title">Click on Pictures to Enlarge</h2>
              <StackGrid
                enableSSR={true}
                monitorImagesLoaded={true}
                columnWidth={300}
                duration={600}
                gutterWidth={10}
                gutterHeight={10}
                easing={easings.cubicOut}
                appearDelay={60}
                appear={transition.appear}
                appeared={transition.appeared}
                enter={transition.enter}
                entered={transition.entered}
                leaved={transition.leaved}
              >
                {photos.map((obj,index) => (
                    <a href="#pablo" onClick={openLightbox}><img src={obj.src} data-index={index} alt="DragonflyOIB Album" /></a>
                ))}
              </StackGrid>

                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: "Book Today!"
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </Col>
            </Row>
          </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default CustomGallery;